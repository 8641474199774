import React from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBTypography,
} from 'mdb-react-ui-kit';
import Footer from '../components/footer';
import pfile from '../assets/images/pfile.png';
import bsgame from '../assets/images/bsgame.PNG';
import todo from '../assets/images/todo.PNG';
import chal from '../assets/images/chal.PNG';
import ci from '../assets/images/ci.PNG';
import Navbar from './navbar';
import Info from './modal';

const Profile = () => {
  const sendEmail = () => {
    const email = 'codesmithr@outlook.com';
    const subject = 'Message from Portfolio Site';
    const emailBody = 'Greetings Ralph, ';
    return document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
  }
 
  let appInfo = (
    <MDBCardText style={{ textAlign: "left"}}> 
      &emsp;&emsp;This simple number guessing game was built in <strong>ReactJS</strong>, using hooks, and uses <strong>Material UI</strong> for styling.
      A binary search algorithm  was implemented to search an array of randomly generated numbers using the user's answer selection. If 
      the guess is incorrect, the algorithm will inform the user that their guess was higher or lower
      than the correct answer. Visit <a href='https://github.com/rlondon3/guess-num-game'>Github</a> to clone the repo; 
      or click on the image to explore the application.
    </MDBCardText>);
  let appInfo2 = (
    <MDBCardText style={{ textAlign: "left"}}>
      &emsp;&emsp;Another simple application built using <strong>ReactJS</strong> on the frontend and <strong>NodeJS</strong> on the backend.
      The React To Do App API uses <strong>Mongo DB</strong> to store user created tasks in a database. The API sends those tasks to the 
      frontend, and React JS displays the user's task in a table. Users may add details to their tasks such as due dates, active, and notes. 
      Furthermore, users are able to edit and delete their tasks at any time. Visit <a href='https://github.com/rlondon3/react-todo-app'>Github</a> to clone the repo;
       or click on the image to explore the application.</ MDBCardText>
  );
  let appInfo3 = (
    <MDBCardText style={{ textAlign: "left"}}>
      &emsp;&emsp;This is a <strong>ReactJS</strong> frontend for an application that would allow users to log book reports based on the books on 
      New York Times Bestsellers list. This UI calls the New York Times API to dynamically render data on tables that are paginated across 5 pages. 
      The <strong>React Context API</strong> was implemented so that the frontend can scale to <strong>Redux</strong> for state management. The application makes full use of
       <strong> React Hooks</strong>, and simply needs a dashboard and backend to be complete. Visit <a href='https://github.com/rlondon3/Reading-Challenge'>Github</a> to clone the repo;
       or click on the image to explore the application.</ MDBCardText>
  );
  let appInfo4 = (
    <iframe width="460" 
    height="315" 
    src="https://www.youtube.com/embed/bRB-Jk56Low" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen></iframe>
  );
    return ( 
      <>
      <section >
      <MDBContainer className="py-5">
        <Navbar />
        <MDBRow>
          <MDBCol>
              <div className="text-white d-flex flex-row" style={{ backgroundColor: '#000', height: '200px', }}>
                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                  <MDBCardImage src={pfile}
                    alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} />
                  <MDBBtn
                    className='message'
                    outline 
                    color='white' 
                    style={{
                    color: 'grey', 
                    border: '1px solid white',
                    boxShadow:' 0 4px 9px -4px #3b71ca',
                    height: '36px', 
                    overflow: 'visible',
                    zIndex: '1'
                    }}
                    onClick={() => sendEmail()}
                    >Message
                    </MDBBtn>
                </div>
                <div className="ms-3" style={{ marginTop: '130px' }}>
                  <MDBTypography tag="h5">Raphael Smith</MDBTypography>
                  <MDBCardText>Shasta Lake, California</MDBCardText>
                </div>
              </div>
              <MDBCard className='gradient-custom-2 text-white' style={{
                borderTopRightRadius: "0px", 
                borderTopLeftRadius: "0px", 
                borderBottomLeftRadius: '15px', 
                borderBottomRightRadius: "15px", boxShadow: "0px 0px 10px black" }}>
              <MDBCardBody id='mainText' className="px-4 mx-5" style={{width: "-webkit-fill-available"}} >
                <MDBTypography className='mt-3' id='mainHeader' style={{ textShadow: "1px 1px 2px grey"}} tag='h3'>Software Developer</MDBTypography>
                <br />
                <MDBCardText className="small">
                  <strong>Skills: </strong>
                  <span className="mx-2">|</span>
                  <strong>JavaScript</strong>&nbsp;-&nbsp; 
                  <strong>C</strong>&nbsp;-&nbsp;
                  <strong>Python</strong>&nbsp;-&nbsp;
                  <strong>React</strong>&nbsp;-&nbsp;
                  <strong>NodeJS</strong>&nbsp;-&nbsp;
                  <strong>Express</strong>&nbsp;-&nbsp;
                  <strong>MongoDB</strong>&nbsp;-&nbsp;
                  <strong>SQL</strong>&nbsp;-&nbsp;
                  <strong>Unit Testing</strong>&nbsp;-&nbsp;
                  <strong>CI/CD</strong>
                </MDBCardText>
                <div style={{ textAlign: "left"}}>
                  <MDBCardText className='pb-4'>
                    &emsp;&emsp;Proven <strong>full-stack</strong> software engineer with bootcamp training certifications and 2+ years’ experience.
                    An <strong>effective communicator</strong> and <strong>problem solver</strong>, I solve real world problems using JavaScript and supporting 
                    languages/libraries/frameworks. Through <strong>internships and work experience</strong>, I have honed best 
                    practice programming skills, and have a deep understanding with working on an <strong>agile</strong> team dedicated to delivering a high functional product to end users.
                  </MDBCardText>
                </div>
                <hr className="mx-4" />
              </MDBCardBody>
              </MDBCard>
              <div className="mt-3" style={{ paddingRight: "0.7rem", paddingLeft: '0.7rem'}}>
              <div className="d-flex justify-content-center align-items-center mb-4">
                <MDBTypography id='projects'  tag='h2' style={{ color: "grey", textShadow: "1px 1px 2px grey" }}>Projects</MDBTypography>
              </div>
                <MDBRow>
                  <MDBCol md='4' className="mb-2">
                  <MDBTypography variant='h6' className='mt-2'>
                    <Info appInfo={appInfo} /> Binary Search Algorithm: Number Guessing Game
                  </MDBTypography>
                  <div
                  className='bg-image hover-overlay m-1' 
                  style={{ position: 'relative'}}
                  >
                    <div
                    style={{ position: 'relative', 
                    textAlign: 'center' }}>
                      <a href='http://binary-search-guessing-game.surge.sh/' >
                      <img
                      className='img-fluid'
                      src={bsgame} 
                      alt='...' 
                       />
                      </a>
                    </div>
                    <hr className="hr-color mx-4" style={{ color: "white"}} />
                  </div>
                  </MDBCol>
                  <MDBCol md='4' className="mb-2">
                  <MDBTypography variant='h6' className='m-2'>
                    <Info appInfo={appInfo2} /> MERN Stack Task Application: To Do App
                  </MDBTypography>
                  <div 
                  className='bg-image hover-overlay m-1'
                  style={{ position: 'relative' }}>
                  <div 
                  style={{ position: 'relative', 
                  textAlign: 'center' }}
                  >
                    <a href='https://myreacttodoapp-9.netlify.app/' >
                      <img 
                      style={{ width: '100%'}} 
                      src={todo} alt='...' 
                      position='top' />
                    </a>
                 </div>
                 <hr className="hr-color mx-4" style={{ color: "white"}} />
                </div>
                  </MDBCol>
                  <MDBCol md='4' className="mb-2">
                  <MDBTypography variant='h6' className='my-2 mb-5'>
                    <Info appInfo={appInfo3} /> ReactJS: Reading Challenge Frontend <br />
                  </MDBTypography>
                  <div 
                  className='bg-image hover-overlay m-1'
                  style={{ position: 'relative' }}>
                    <div 
                    style={{ position: 'relative', 
                    textAlign: 'center' }}
                    >
                      <a href='https://alluring-bears.surge.sh/'>
                        <img style={{ width: "100%" }} src={chal} alt='...' position='top' />
                      </a>
                    </div>
                  </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mb-2' style={{ borderBottom: "solid white"}}>
                <MDBCard className='gradient-custom-2 text-white' style={{
                borderTopRightRadius: "15px", 
                borderTopLeftRadius: "15px", 
                borderBottomLeftRadius: '0px', 
                borderBottomRightRadius: "0px", boxShadow: "0px 0px 10px black" }}>
                <MDBCardBody style={{width: "-webkit-fill-available"}}>
                <MDBTypography id='projects' className='mt-3'  tag='h2' style={{ color: "grey", textShadow: "1px 1px 2px grey" }}>Technical Projects</MDBTypography>
                <hr className="my-4 mx-5" />
                <div className="d-flex justify-content-center align-items-center mb-4">
                  <MDBTypography id='techProjects' className='mt-3'  tag='h5'><Info appInfo={appInfo4} /> Authenticate Users</MDBTypography>
                </div>
                  <a href='https://github.com/rlondon3/authenticate-user'>
                  <MDBCol>
                      <img src={ci}
                        alt="10" className="w-100 rounded-3"  />
                  </MDBCol>
                  </a>
                  </MDBCardBody>
                  </MDBCard>
                  <MDBCard className='bg-black'style={{
                borderTopRightRadius: "0px", 
                borderTopLeftRadius: "0px", 
                borderBottomLeftRadius: '15px', 
                borderBottomRightRadius: "15px", boxShadow: "0px 0px 10px black" }}>
                  <Footer />
                  </MDBCard>
                </MDBRow>
                
              </div>

              
          </MDBCol>
        </MDBRow>
        
      </MDBContainer>
    </section>
    </>
     );
}
 
export default Profile;