import React, { useState } from 'react';
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

const Info = ({ appInfo }) => {
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);
    return ( 
        <>
    <MDBIcon className='info' fas icon="info-circle" onClick={toggleShow} />
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader style={{ alignSelf: 'center' }}>
              <MDBModalTitle>Application Detail</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>{appInfo}</MDBModalBody>

            <MDBModalFooter style={{ alignSelf: 'center' }}>
              <MDBBtn color='secondary' onClick={toggleShow} >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
     );

}
 
export default Info;