import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBRow,
  MDBBtn
} from 'mdb-react-ui-kit';
import avatar from '../assets/images/avatar.png';

const Footer = () => {
  return (
    <MDBFooter id='about' className='text-center' color='white'>
      <MDBContainer className='p-2'>
        <section>
        <MDBRow className="justify-content-center">
          <MDBCol>
            <MDBCard className="mb-4" style={{ backgroundColor: 'black' }}>
              <MDBCardBody className="text-center">
              <MDBTypography style={{ color: "grey", textShadow: "1px 1px 2px grey" }} tag='h2'>About Me</MDBTypography>
                <MDBCardImage
                  src={avatar}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
        <div className="text-muted mb-1">
        <section>
          <MDBBtn style={{ boxShadow:' 0 4px 9px -4px #3b71ca'}} outline color="light" floating className='m-1' href='https://mobile.twitter.com/codesmithr' role='button'>
            <MDBIcon fab icon='twitter' />
          </MDBBtn>
        
          <MDBBtn style={{ boxShadow:' 0 4px 9px -4px #3b71ca'}} outline color="light" floating className='m-1' href='https://www.linkedin.com/in/raphael-smith-b91a65206/' role='button'>
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn>

          <MDBBtn style={{ boxShadow:' 0 4px 9px -4px #3b71ca'}} outline color="light" floating className='m-1' href='https://github.com/rlondon3' role='button'>
            <MDBIcon fab icon='github' />
          </MDBBtn>

          <MDBBtn style={{ boxShadow:' 0 4px 9px -4px #3b71ca'}} outline color="light" floating className='m-1' href='https://programmingthestreets.hashnode.dev/' role='button'>
          <MDBIcon fas icon="blog" />
          </MDBBtn>
            </section>
            </div>
              </MDBCardBody>
            </MDBCard>
            </MDBCol>
        </MDBRow>
        </section>
        <section>
          <p>
            Full-stack JavaScript developer specializing in building web applications with CI/CD pipelines.
            Always a learner, I am presently learning Linux and C++; and have an interest in building web games/animations. 
            In my freetime, I am martial artist, musician, philosopher/theologian, and a father to a very curious little boy. 
          </p>
          <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
            © 2023 Copyright:&nbsp;
            <a className='text-white' href='www.codesmithr.com'>
              www.codesmithr.com
            </a>
          </div>
        </section>
      </MDBContainer>
    </MDBFooter>
  );
}
export default Footer;